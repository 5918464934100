import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";
import { FC } from "react";
import BaseLayout, { LayoutProps } from "./BaseLayout";
import dynamic from "next/dynamic";
import EmailVerificationAlert from "@/features/email_verification/components/EmailVerificationAlert";
import clsx from "clsx";
import { HeaderProvider } from "@/contexts/HeaderContext";
import { useMeasure } from "react-use";
import { ModalEntityListProvider } from "@/contexts/ModalEntityListContext";

const DynamicModalShowDetail = dynamic(
  () => import("@/components/ModalShowDetail"),
);

const DefaultLayout: FC<
  LayoutProps & { hiddenFooter?: boolean; className?: string }
> = ({
  children,
  requiredAuth,
  show,
  hiddenFooter = false,
  className,
}): JSX.Element => {
  const [headerRef, headerSize] = useMeasure();

  return (
    <BaseLayout requiredAuth={requiredAuth} show={show}>
      <HeaderProvider size={headerSize}>
        <ModalEntityListProvider>
          <div className={clsx("bg-main", className)}>
            <div className="sticky top-0 z-[999]" ref={headerRef}>
              <EmailVerificationAlert />
              <TheHeader />
            </div>
            <div>
              <div className="min-h-screen">{children}</div>

              {!hiddenFooter && <TheFooter />}
            </div>
          </div>

          <DynamicModalShowDetail />
        </ModalEntityListProvider>
      </HeaderProvider>
    </BaseLayout>
  );
};

export default DefaultLayout;
