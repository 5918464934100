import { ModalEntityType } from "@/components/LinkAsModal";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useCallback, useMemo } from "react";
import { UrlObject } from "url";

type HrefArgs = {
  entityId: number;
  entityType: ModalEntityType;
  params?: ParsedUrlQuery;
};

type PushArgs = {
  as: string;
} & HrefArgs;

interface UseLinkAsModalRetrun {
  isOpen: boolean;
  href(args: HrefArgs): UrlObject;
  push(args: PushArgs): void;
}

const useLinkAsModal = (): UseLinkAsModalRetrun => {
  const router = useRouter();

  const isOpen = useMemo<boolean>(() => {
    return !!router.query.entityId && !!router.query.entityType;
  }, [router]);

  const href = useCallback<(args: HrefArgs) => UrlObject>(
    ({ params, entityId, entityType }: HrefArgs) => {
      return {
        pathname: router.pathname,
        query: { ...(router.query || {}), ...params, entityId, entityType },
      };
    },
    [router],
  );

  const push = useCallback<(args: PushArgs) => void>(
    (args) => {
      const url = href(args);
      const options = {
        shallow: true,
        scroll: false,
      };

      isOpen
        ? router.replace(url, args.as, options)
        : router.push(url, args.as, options);
    },
    [router, href, isOpen],
  );

  return {
    isOpen,
    href,
    push,
  };
};

export default useLinkAsModal;
