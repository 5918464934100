import DefaultLayout from "@/layouts/DefaultLayout";
import { NextPage } from "next";
import Link from "next/link";

interface Props {
  statusCode: number;
  label: string;
}

const ErrorPage: NextPage<Props> = ({ statusCode, label }): JSX.Element => {
  return (
    <DefaultLayout>
      <div className="flex items-center justify-center w-full">
        <div className="text-center space-y-20">
          <div className="space-y-4">
            <h1 className="text-[100px] font-bold leading-none">
              {statusCode}
            </h1>
            <p className="text-sm font-bold">{label}</p>
          </div>
          <div>
            <Link href="/" className="text-sm">
              トップへ戻る
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err?.statusCode || 404;
  const label =
    statusCode == 404 ? "ページが見つかりません" : "不明なエラーが発生しました";

  return { statusCode, label };
};

export default ErrorPage;
