import { createContext, PropsWithChildren, useContext } from "react";
import { UseMeasureRect } from "react-use/lib/useMeasure";

interface HeaderContextProps {
  size: UseMeasureRect;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const useHeaderContext = (): HeaderContextProps => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }

  return context;
};

export const HeaderProvider: React.FC<
  PropsWithChildren<HeaderContextProps>
> = ({ children, size }) => {
  return (
    <HeaderContext.Provider value={{ size }}>{children}</HeaderContext.Provider>
  );
};
