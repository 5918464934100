import { useSelector } from "react-redux";
import { RootState } from "@/stores/rootReducer";
import { sendEmailVerification } from "firebase/auth";
import { useCallback, useMemo, useState } from "react";
import { auth } from "@/utils/firebase";
import clsx from "clsx";
import Button from "@/components/Button";

const EmailVerificationAlert = () => {
  const { isAuthenticated, emailVerified } = useSelector(
    (state: RootState) => state.auth,
  );

  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const resendEmailVerification = useCallback(async () => {
    setIsSendingEmail(true);

    await sendEmailVerification(auth.currentUser);

    setIsSendingEmail(false);
    setHasSentEmail(true);
  }, []);

  const disable = useMemo(
    () => hasSentEmail || isSendingEmail,
    [hasSentEmail, isSendingEmail],
  );

  // 未認証だったら何も表示しない
  if (!isAuthenticated) return null;

  // 未認証かつメール確認済みだったら何も表示しない
  if (emailVerified && isAuthenticated) return null;

  return (
    <div
      className={clsx(
        "sticky bottom-0 inset-x-0",
        "w-full bg-yellow-500 grid ",
        ["laptop:grid-flow-col laptop:grid-cols-[4fr_1fr]"],
        ["laptop:gap-32", "gap-12"],
        ["laptop:px-32", "px-12 py-12"],
        "justify-items-start items-center",
        "z-50",
      )}
    >
      <div className="grid gap-4 laptop:gap-24 laptop:grid-flow-col">
        <div className={clsx("font-bold text-sm", "laptop:text-base")}>
          メール認証のお願い
        </div>

        <p className={clsx("text-xs", "laptop:text-sm")}>
          メールアドレス宛に認証メールを送信しました。
        </p>
      </div>

      <div className="w-full [&>*]:w-full">
        <Button
          onClick={resendEmailVerification}
          type="button"
          disabled={disable}
          color={"outlined"}
        >
          {isSendingEmail ? (
            <>メールを再送信</>
          ) : (
            <>{hasSentEmail ? "送信済み" : "メールを再送信"}</>
          )}
        </Button>
      </div>
    </div>
  );
};
export default EmailVerificationAlert;
