import { ModalEntityType } from "@/components/LinkAsModal";
import { FavoriteItemResponse } from "@/types/FavoriteItemResponse";
import {
  ListItemKind,
  PostResponse,
  ProductVariationWithProductResponse,
  ProjectResponse,
} from "@/web-client";
import { ParsedUrlQuery } from "querystring";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type Response =
  | Pick<PostResponse, "id" | "pins">
  | Pick<ProjectResponse, "id" | "title">
  | Pick<ProductVariationWithProductResponse, "id" | "product_id">
  | FavoriteItemResponse;

interface ModalEntity {
  entityId: number;
  entityType: ModalEntityType;
  as: string;
  params?: ParsedUrlQuery;
}

interface ModalEntityListContextType {
  responses: Response[];
  setResponses: (responses: Response[]) => void;
  setCurrent: (
    entityId: number,
    entityType: ModalEntityType,
    variationId?: number,
  ) => void;
  nextModalEntity: ModalEntity;
  prevModalEntity: ModalEntity;

  disablePagination: boolean;
  setDisablePagination: (disable: boolean) => void;
}

const isProject = (response: Response): response is ProjectResponse => {
  return "title" in response;
};

const isPost = (response: Response): response is PostResponse => {
  return "pins" in response;
};

const isProductVariation = (
  response: Response,
): response is ProductVariationWithProductResponse => {
  return "product_id" in response;
};

const isFavoriteItem = (
  response: Response,
): response is FavoriteItemResponse => {
  return "detailable_id" in response;
};

const ModalEntityListContext = createContext<
  ModalEntityListContextType | undefined
>(undefined);

export const useModalEntityListContext = (): ModalEntityListContextType => {
  const context = useContext(ModalEntityListContext);

  if (!context) {
    throw new Error(
      "useModalEntityListContext must be used within a ModalEntityListProvider",
    );
  }

  return context;
};

export const ModalEntityListProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [responses, setResponses] = useState<Response[]>([]);
  const [convertedResponses, setConvertedResponses] = useState<Response[]>([]);
  const [currentEntityId, setCurrentEntityId] = useState<number>();
  const [currentEntityType, setCurrentEntityType] = useState<ModalEntityType>();
  const [currentVariationId, setCurrentVariationId] = useState<number>();
  const [disablePagination, setDisablePagination] = useState<boolean>(false);

  const setCurrent = useCallback(
    (entityId: number, entityType: ModalEntityType, variationId?: number) => {
      setCurrentEntityId(entityId);
      setCurrentEntityType(entityType);
      setCurrentVariationId(variationId);
    },
    [setCurrentEntityId, setCurrentEntityType, setCurrentVariationId],
  );

  const currentIndex = useMemo<number | undefined>(() => {
    if (currentEntityType === ModalEntityType.Post) {
      return convertedResponses.findIndex(
        (e) => isPost(e) && e.id === currentEntityId,
      );
    }

    if (currentEntityType === ModalEntityType.Project) {
      return convertedResponses.findIndex(
        (e) => isProject(e) && e.id === currentEntityId,
      );
    }

    if (currentEntityType === ModalEntityType.Product) {
      return convertedResponses.findIndex(
        (e) => isProductVariation(e) && e.id === currentVariationId,
      );
    }
  }, [
    convertedResponses,
    currentEntityType,
    currentEntityId,
    currentVariationId,
  ]);

  const getModalUrl = useCallback((response: Response) => {
    if (isPost(response)) {
      return `/posts/${response.id}`;
    }

    if (isProject(response)) {
      return `/projects/${response.id}`;
    }

    if (isProductVariation(response)) {
      return `/products/${response.product_id}?v_id=${response.id}`;
    }
  }, []);

  const getModalEntityType = useCallback<
    (response: Response) => ModalEntityType
  >((response) => {
    if (isPost(response)) {
      return ModalEntityType.Post;
    }

    if (isProject(response)) {
      return ModalEntityType.Project;
    }

    if (isProductVariation(response)) {
      return ModalEntityType.Product;
    }
  }, []);

  const getModalEntityId = useCallback<(response: Response) => number>(
    (response: Response) => {
      if (isProductVariation(response)) {
        return response.product_id;
      }

      return response.id;
    },
    [],
  );

  const getModalParams = useCallback<(response: Response) => ParsedUrlQuery>(
    (response: Response) => {
      if (isProductVariation(response)) {
        return { v_id: response.id.toString() };
      }

      return {};
    },
    [],
  );

  const getModalEntity = useCallback<(response: Response) => ModalEntity>(
    (response) => {
      return {
        entityId: getModalEntityId(response),
        entityType: getModalEntityType(response),
        as: getModalUrl(response),
        params: getModalParams(response),
      };
    },
    [getModalEntityType, getModalUrl, getModalEntityId, getModalParams],
  );

  const findDifferentResponse = useCallback(
    (responses: Response[]) => {
      return responses.find((r) => {
        return (
          getModalEntityType(r) != currentEntityType ||
          getModalEntityId(r) != currentEntityId
        );
      });
    },
    [currentEntityType, currentEntityId, getModalEntityType, getModalEntityId],
  );

  const prevModalEntity = useMemo<ModalEntity | undefined>(() => {
    if (disablePagination) return undefined;

    if (currentIndex === -1) {
      return undefined;
    }

    const prev = findDifferentResponse(
      convertedResponses.slice(0, currentIndex).reverse(),
    );

    return prev ? getModalEntity(prev) : undefined;
  }, [
    getModalEntity,
    currentIndex,
    convertedResponses,
    findDifferentResponse,
    disablePagination,
  ]);

  const nextModalEntity = useMemo<ModalEntity | undefined>(() => {
    if (disablePagination) return undefined;

    if (currentIndex === -1) {
      return undefined;
    }

    const next = findDifferentResponse(
      convertedResponses.slice(currentIndex + 1),
    );

    return next ? getModalEntity(next) : undefined;
  }, [
    getModalEntity,
    currentIndex,
    convertedResponses,
    findDifferentResponse,
    disablePagination,
  ]);

  useEffect(() => {
    setConvertedResponses(
      responses.map((r) => {
        if (!isFavoriteItem(r)) {
          return r;
        }

        if (r.kind == ListItemKind.POST) {
          return { id: r.detailable_id.post_id, pins: [] };
        }

        if (r.kind == ListItemKind.PROJECT) {
          return { id: r.detailable_id.project_id, title: "" };
        }

        return {
          id: r.detailable_id.product_variation_id,
          product_id: r.detailable_id.product_id,
        };
      }),
    );
  }, [responses, setConvertedResponses]);

  return (
    <ModalEntityListContext.Provider
      value={{
        responses,
        setResponses,
        setCurrent,
        prevModalEntity,
        nextModalEntity,
        disablePagination,
        setDisablePagination,
      }}
    >
      {children}
    </ModalEntityListContext.Provider>
  );
};
